import '../../../styles/profile.css';
import RadialChart from '../../molecules/RadialChart';
import { FEEDBACK, PROFILE_CAREER, PROFILE_CERTIFICATION, PROFILE_EDUCATION } from '../../../mockData/mock';
import Button from '../../atoms/Button';
import { getEstimateCookieDataAndDecrypt } from '../../../lib/Util';
import { setSheetOshiNoPartner } from '../../../lib/EstimateApi';
// import Button from '../../atoms/Button';
// import Header3 from '../../molecules/Header3';
// import useCheckPC from '../../../hooks/useCheckPC';
// import Header from '../../molecules/Header';

import withReactContent from 'sweetalert2-react-content';
import SwalTitleComponent from '../ManageReservation/Modal/Common/SwalTitleComponent';
import Swal from 'sweetalert2';


const ProfileEstimate = ({ selectedPartnerInfo, modalClose }) => {

	const showModalDetail = async (title, content) => {
		//console.log(reportResult.SelectDriverFee)
		const mySwal = withReactContent(Swal)
		mySwal.fire({
			title: (
				<SwalTitleComponent
					title={title}
					cancelBtn={mySwal.clickCancel} />
			),
			html: (
				<>
					<div className='desc'>
						{content}
					</div>
				</>

			),
			preConfirm: () => {

			},
			confirmButtonText: '확인',
			confirmButtonColor: '#1eafd2',
		})
	}

	const showModalDetailImg = async (title, content) => {
		//console.log(reportResult.SelectDriverFee)
		const mySwal = withReactContent(Swal)
		mySwal.fire({
			title: (
				<SwalTitleComponent
					title={title}
					cancelBtn={mySwal.clickCancel} />
			),
			html: (
				<>
					<div style={{height: '100%', overflow: 'hidden'}}>
						<img src={content} style={{width:'100%'}}/>
					</div>
				</>

			),
			preConfirm: () => {

			},
			confirmButtonText: '확인',
			confirmButtonColor: '#1eafd2',
		})
	}

	const onButtonProcess = () => {
		let userInfoDecrypt = getEstimateCookieDataAndDecrypt();
		let EstimateResponseObj = {
			UserName: userInfoDecrypt.UserName,
			UserPhone: userInfoDecrypt.PhoneNumber,
			UserEmail: userInfoDecrypt.EmailAddress,
			PartnerName: selectedPartnerInfo?.FullName,
			DriverNo: selectedPartnerInfo?.DriverNo
		}
		
		if (document.location.hostname.indexOf('yeartest.mosiler.com') >= 0) {
		
		}
		else {
			setSheetOshiNoPartner(EstimateResponseObj)
			modalClose()
			showModalDetail('파트너 선택 완료', '파트너 선택이 완료 되었습니다.')
		}
		
	}

	function reduceArrayBy85Percent(arr) {
		return arr?.map(value => value * 0.85);
	}

	return (
		<>
			<div className='profile-wrap-custom'>
				<div className='profile-section column'>
					<div className='profile-section-img-wrap' onClick={()=>{
						showModalDetailImg('',selectedPartnerInfo?.img)
						
					}}>
						<img src={selectedPartnerInfo?.img} className='image' alt='Driver' />
					</div>
					<span className='name'>{selectedPartnerInfo?.DriverName} 파트너</span>
					<div className='tag-list'>
						<span className='tag'>{selectedPartnerInfo?.Age}</span>
						{/* <span className='tag'>모시러 경험자</span> */}
					</div>
				</div>
				<div className='profile-section'>
					<div className='inner'>
						<div className='inner-item-custom'>
							<div className='chart-wrap'>
								<RadialChart dataList={reduceArrayBy85Percent(selectedPartnerInfo?.GraphData)} />
							</div>
						</div>
						{(selectedPartnerInfo?.PersonalHistory && selectedPartnerInfo?.PersonalHistory?.length >= 1) && <div className='inner-item'>
							<span className='title'>이력</span>
							<ul className='time-line'>
								{selectedPartnerInfo?.PersonalHistory?.map((item, index) => (
									<li key={index}>
										{item.time || (item.startTime && <span className='time'>{item.time ? item.time : item.startTime + ' ~ ' + item.endTime}</span>)}
										<span className='time-line-title'>{item.title}</span>
									</li>
								))}
							</ul>
						</div>}

						{(selectedPartnerInfo?.EducationHistory && selectedPartnerInfo?.EducationHistory?.length >= 1) && <div className='inner-item'>
							<span className='title'>학력</span>
							<ul className='time-line'>
								{selectedPartnerInfo?.EducationHistory.map((item, index) => (
									<li key={index}>
										{item.time || (item.startTime && <span className='time'>{item.time ? item.time : item.startTime + ' ~ ' + item.endTime}</span>)}
										<span className='time-line-title'>{item.title}</span>
									</li>
								))}
							</ul>
						</div>}

						{(selectedPartnerInfo?.CertificateHistory && selectedPartnerInfo?.CertificateHistory?.length >= 1) && <div className='inner-item'>
							<span className='title'>자격증</span>
							<ul className='time-line'>
								{selectedPartnerInfo?.CertificateHistory.map((item, index) => (
									<li key={index}>
										{(item.time || item.startTime) && <span className='time'>{item.time ? item.time : item.startTime + ' ~ ' + item.endTime}</span>}
										<span className='time-line-title'>{item.title}</span>
									</li>
								))}
							</ul>
						</div>}

						{selectedPartnerInfo?.Feedback && <div className='inner-item'>
							<span className='title'>교관 피드백</span>
							<div className='feedback-wrap'>
								<span className='contents' dangerouslySetInnerHTML={{ __html: selectedPartnerInfo.Feedback.Comment }}></span>
								<span className='label'>
									{selectedPartnerInfo.Feedback.Teacher} · {selectedPartnerInfo.Feedback.Date}
								</span>
							</div>
						</div>}
						

						<div className='inner-item'>
							<span className='title'>거주지역</span>
							<span className='value'>{selectedPartnerInfo.Address}</span>
						</div>
						<div className='inner-item'>
							<span className='title'>흡연여부</span>
							<span className='value'>{selectedPartnerInfo.SmokingStatus}</span>
						</div>
						<div className='inner-item'>
							<span className='title'>결혼여부</span>
							<span className='value'>{selectedPartnerInfo.MarriedStatus}</span>
						</div>
						<div className='inner-item'>
							<span className='title'>아이유무</span>
							<span className='value'>{selectedPartnerInfo.BabyStatus}</span>
						</div>
						<div className='inner-item'>
							<span className='title'>자기소개</span>
							<span className='value'>{selectedPartnerInfo.Introduce}</span>
						</div>

					</div>

				</div>
			</div>

			<div className='button-wrap'>
				<Button styleType='secondary' onClick={onButtonProcess}>이 파트너를 원해요</Button>
			</div>
		</>
	);
};

export default ProfileEstimate;
